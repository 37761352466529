import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const ImagePill = ({ blade: { image, rounded } }) => {
  const img = getImage(image.gatsbyImageData)

  return (
    <div
      className={`flex ${
        rounded === "left"
          ? "justify-end pl-12 md:pl-20 lg:pl-32"
          : "justify-start pr-12 md:pr-20 lg:pr-32"
      }`}
    >
      <GatsbyImage
        image={img}
        alt={image.alt ? image.alt : "Nearlight Studio"}
        className={`w-full max-w-6xl relative h-full ${
          rounded === "left" ? "rounded-l-full" : "rounded-r-full"
        } z-30`}
        style={{ position: null }}
        objectFit="cover"
      />
    </div>
  )
}

export default ImagePill

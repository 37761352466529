import * as React from "react"
import { graphql } from "gatsby"

import Blades from "@components/Blades"
import Root from "@hoc/Root"
import Meta from "@hoc/Meta"

// markup
const IndexPage = ({ data }) => {
  const page = data.datoCmsNearlightStudio
  const seo = data.datoCmsNearlightStudio.seo

  return (
    <Root headerColour={page?.headerColour}>
      <Meta {...{ seo }} />
      <Blades {...data.datoCmsNearlightStudio} />
    </Root>
  )
}

export default IndexPage

export const query = graphql`
  query Homepage {
    datoCmsNearlightStudio {
      id
      seo {
        description
        title
        twitterCard
        image {
          url
        }
      }
      blades {
        ... on DatoCmsImageHero {
          ...ImageHero
        }
        ... on DatoCmsMissionStatementOrbital {
          ...MissionStatementOrbital
        }
        ... on DatoCmsMeetTheTeamOrbital {
          ...MeetTheTeamOrbital
        }
        ... on DatoCmsStudioLifeOrbital {
          ...StudioLifeOrbital
        }
        ... on DatoCmsImagePillOrbital {
          ...ImagePillOrbital
        }
        ... on DatoCmsCareersArchive {
          ...CareersArchive
        }
        ... on DatoCmsPressReleaseBlock {
          ...PressReleases
        }
        ... on DatoCmsNdreamsStudioProject {
          ...Projects
        }
      }
    }
  }
`

import Button from "@components/Button"
import PressReleaseCard from "@components/PressReleaseCard"

import { useState } from "react"
import "./styles.scss"
import React from "react"

const PressReleases = ({
  blade: { heading, subHeading, sideTag, pressReleases },
}) => {
  const [showFirst, setShowFirst] = useState(3)
  return (
    <div
      id="press-releases"
      className="py-12 md:py-16 xl:py-28 bg-bare relative"
    >
      <div className="container mx-auto relative px-10">
        <div className="grid grid-cols-1 md:grid-cols-14">
          <div className="col-span-full xl:col-span-12 xl:col-start-2">
            <div className="flex flex-col md:mb-12">
              <h2 className="flex-1 text-3xl md:text-4xl lg:text-5xl font-bold text-pink uppercase">
                {heading}
              </h2>
              <h2 className="flex-1 text-3xl md:text-4xl lg:text-5xl font-bold text-black uppercase">
                {subHeading}
              </h2>
            </div>
            <div className="flex">
              <div className="hidden md:flex flex-1 sideways-text uppercase rotate-180 justify-end mr-4 font-semibold text-lg mt-8 md:mt-0">
                {sideTag}
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-8 md:mt-0 w-full">
                {pressReleases?.length > 0 ? (
                  <>
                    {pressReleases.map(({ title, image, externalLink }, i) => (
                      <PressReleaseCard
                        {...{
                          hide: i >= showFirst,
                          title,
                          image,
                          externalLink,
                        }}
                      />
                    ))}
                  </>
                ) : (
                  <p className="col-span-full w-full text-lg md:text-xl">
                    No open vacancies, please check back in the future.
                  </p>
                )}
              </div>
            </div>

            {showFirst < pressReleases.length ? (
              <div className="mt-8 md:mt-12 text-center">
                <Button
                  className="mx-auto hover:bg-black hover:border-black"
                  clickHandler={() => setShowFirst(showFirst + 3)}
                >
                  Load more
                </Button>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}

export default PressReleases

import React from "react"
import ReactMarkdown from "react-markdown"
import { getImage, GatsbyImage, StaticImage } from "gatsby-plugin-image"
import backgroundImg from "@images/img-sunset-lines-x2.png"
import backgroundImg2 from "@images/img-lines-100opacity-x2.png"

const MissionStatement = ({ blade: { heading, body, logo, image } }) => {
  const _logo = getImage(logo?.gatsbyImageData)
  const _image = getImage(image?.gatsbyImageData)
  console.log({ body })
  return (
    <div className="w-full pb-28 -mb-28 relative overflow-hidden">
      {/* <span className="block absolute top-4 left-[calc(50%-7.5rem)] sm:left-[calc(50%-12rem)] xl:left-[calc(50%-15rem)] transform  opacity-5">
        <img
          src={backgroundImg2}
          className="w-[15rem] sm:w-[24rem] xl:w-[30rem] h-auto"
        />
      </span>
      <span className="block absolute top-0 -left-0 transform -translate-x-1/2 translate-y-1/2 opacity-20 md:opacity-100">
        <img src={backgroundImg} className=" w-[40rem] h-auto" />
      </span> */}

      <div className="container mx-auto py-12 md:py-20 px-10 relative text-center z-10">
        <div className="flex flex-col justify-center items-center mt-4 md:mt-8 lg:mt-0">
          {_logo && (
            <GatsbyImage
              className="max-w-[200px] md:max-w-[340px] mx-auto block mb-12 md:mb-20 drop-shadow-xl"
              image={_logo}
              objectFit="contain"
              objectPosition="center"
              alt={logo.alt ? logo.alt : "Nearlight Studio"}
            />
          )}

          <h1 className="max-w-2xl mx-auto mb-8 md:mb-12 relative text-3xl md:text-4xl lg:text-5xl font-semibold text-pink !leading-[1.1] z-20">
            <ReactMarkdown children={heading}></ReactMarkdown>
          </h1>

          <div className="max-w-4xl mx-auto">
            <ReactMarkdown children={body} className="wysiwyg"></ReactMarkdown>
          </div>

          {_image && (
            <div className="relative">
              <span className="block absolute top-[calc(100%+1rem)] left-[calc(50%-6rem)] md:left-[calc(50%-10rem)] lg:left-[calc(50%-12rem)] xl:left-[calc(50%-15rem)] transform  opacity-5">
                <img
                  src={backgroundImg2}
                  className="w-[12rem] md:w-[20rem] lg:w-[24rem] xl:w-[30rem] h-auto"
                />
              </span>

              <GatsbyImage
                className="w-full mx-auto block mt-12 md:mt-20 rounded-2xl"
                image={_image}
                objectFit="contain"
                objectPosition="center"
                alt={image.alt ? image.alt : "Nearlight Studio"}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default MissionStatement

import Link from "@components/Link";
import React, { useState } from "react";
import { useMenuState } from "@context";
import Icon from "@components/Icon";

export const Sidedrawer = React.memo(({ studios }) => {
  const { menuOpen, setMenuOpen } = useMenuState();

  const [studioOpen, setStudioOpen] = useState(false);
  const [aboutOpen, setAboutOpen] = useState(false);
  const nDreams = "https://ndreams.com";
  const [careersOpen, setCareersOpen] = useState(false);

  return (
    <div
      className={`bg-black transition-all delay-400 w-full fixed top-0 left-0 h-full z-40 ${
        menuOpen
          ? "opacity-100 visible pointer-events-auto lg:hidden"
          : "opacity-0 pointer-events-none lg:hidden"
      } lg:hidden`}
    >
      <nav className="flex items-center justify-center w-full h-full text-white">
        <ul className="flex flex-col items-center justify-center w-full mx-auto text-center">
          <li className="hover:text-secondary w-full">
            <Link
              to="https://ndreams.com/games"
              external={true}
              className="block py-4 text-2xl font-bold uppercase hover:text-secondary transition duration-200"
            >
              Games
            </Link>
          </li>
          <li className="w-full">
            <Link
              to="https://ndreams.com/news"
              external={true}
              newTab={false}
              className="block py-4 text-2xl font-bold uppercase hover:text-secondary transition duration-200"
            >
              News
            </Link>
          </li>
          <li className="w-full">
            <Link
              to="https://ndreams.com/careers"
              external={true}
              newTab={false}
              className="block py-4 text-2xl font-bold uppercase hover:text-secondary transition duration-200"
            >
              Careers
            </Link>
          </li>
          <li className="text-center w-full">
            <div
              className={`py-4 text-2xl font-bold uppercase hover:text-secondary transition duration-200 text-center flex items-center justify-center cursor-pointer ${
                studioOpen && "bg-secondary text-white hover:text-blue-light"
              }`}
              onClick={() => setStudioOpen(!studioOpen)}
            >
              <p className="relative w-fit flex items-center justify-center">
                Studio{" "}
                <Icon
                  {...{
                    name: "angleDown",
                    className: `text-secondary absolute -right-6 w-4 ${
                      studioOpen && "!text-white"
                    }`,
                  }}
                />
              </p>
            </div>
            <ul
              className={`bg-blue-light w-full ${
                studioOpen ? "block" : "hidden"
              }`}
            >
              <li>
                <Link
                  to="https://www.studio.ndreams.com/"
                  external={true}
                  newTab={false}
                  className="block py-4 text-2xl font-bold uppercase hover:text-secondary transition duration-200"
                >
                  nDreams
                </Link>
              </li>
              <li>
                <Link
                  to="/"
                  newTab={false}
                  external={false}
                  className="block py-4 text-2xl font-bold uppercase hover:text-secondary transition duration-200"
                >
                  Orbital
                </Link>
              </li>
              <li>
                <Link
                  to="https://www.elevation.ndreams.com/"
                  newTab={false}
                  external={true}
                  className="block py-4 text-2xl font-bold uppercase hover:text-secondary transition duration-200"
                >
                  Elevation
                </Link>
              </li>
              <li>
                <Link
                  to="https://nearlight.co.uk/"
                  newTab={false}
                  external={true}
                  className="block py-4 text-2xl font-bold uppercase hover:text-secondary transition duration-200"
                >
                  Nearlight
                </Link>
              </li>
            </ul>
          </li>

          {/* <li className='text-center w-full'>
                <div className={`py-4 text-2xl font-bold uppercase hover:text-secondary transition duration-200 text-center flex items-center justify-center cursor-pointer ${aboutOpen && 'bg-secondary text-white hover:text-blue-light'}`} onClick={() => setAboutOpen(!aboutOpen)}>
                    <p className="relative w-fit flex items-center justify-center">About <Icon {...{
                        name: 'angleDown',
                        className: `overflow-visible text-secondary absolute -right-6 w-4 ${aboutOpen && '!text-white'}`
                    }} /></p>
                </div>
                <ul className={`bg-blue-light w-full ${aboutOpen ? 'block' : 'hidden'}`}>
                    <li><Link to="https://ndreams.com/development" className="block py-4 text-2xl font-bold uppercase hover:text-secondary transition duration-200">Development</Link></li>
                     <li><Link to="/news" className="block py-4 text-2xl font-bold uppercase hover:text-secondary transition duration-200">Publishing</Link></li>
                </ul>
            </li> */}
          <li className="w-full">
            <Link
              to="https://ndreams.com/about"
              external={true}
              className="block py-4 text-2xl font-bold uppercase hover:text-secondary transition duration-200"
            >
              About
            </Link>
          </li>
          <li className="text-center w-full">
            <div
              className={`py-4 text-2xl font-bold uppercase hover:text-secondary transition duration-200 text-center flex items-center justify-center cursor-pointer ${
                careersOpen && "bg-secondary text-white hover:text-blue-light"
              }`}
              onClick={() => setCareersOpen(!careersOpen)}
            >
              <p className="relative w-fit flex items-center justify-center">
                Careers{" "}
                <Icon
                  {...{
                    name: "angleDown",
                    className: `text-secondary absolute -right-6 w-4 ${
                      careersOpen && "!text-white"
                    }`,
                  }}
                />
              </p>
            </div>
            <ul
              className={`bg-blue-light w-full ${
                careersOpen ? "block" : "hidden"
              }`}
            >
              <li>
                <a
                  href="https://ndreams.com/careers"
                  className="block py-4 text-2xl font-bold uppercase hover:text-secondary transition duration-200"
                >
                  Work for nDreams
                </a>
              </li>
              <li>
                <a
                  href="https://ndreams.com/academy"
                  className="block py-4 text-2xl font-bold uppercase hover:text-secondary transition duration-200"
                >
                  nDreams Academy
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </div>
  );
});

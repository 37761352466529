import React, { useEffect, useRef, useState } from "react"

import { getImage, GatsbyImage } from "gatsby-plugin-image"

import LinkWithIcon from "@components/LinkWithIcon"

import ReactMarkdown from "react-markdown"

import "./styles.scss"

const ImageHero = ({ blade: { image, heading, video, studioLogo } }) => {
  console.log({ image })
  const parentRef = useRef(null)
  const [scrollTop, setScrollTop] = useState(0)

  useEffect(() => {
    const onScroll = (e) => {
      setScrollTop(e.target.documentElement.scrollTop)
    }
    window.addEventListener("scroll", onScroll)

    return () => window.removeEventListener("scroll", onScroll)
  }, [scrollTop])

  const handleDownClick = (e) => {
    e.preventDefault()

    if (parentRef?.current) {
      window.scrollTo({
        left: 0,
        top:
          parentRef?.current?.getBoundingClientRect()?.y +
          parentRef?.current?.getBoundingClientRect()?.bottom,
        behavior: "smooth",
      })
    }
  }

  const background = getImage(image.gatsbyImageData)
  const logo = getImage(studioLogo?.gatsbyImageData)

  return (
    <div
      className="bg-black max-h-screen relative overflow-hidden text-center h-full"
      ref={parentRef}
    >
      <GatsbyImage
        image={background}
        alt="something"
        objectFit="cover"
        objectPosition="center"
        className="image-hero__bg w-full h-full z-0 max-h-screen max-w-none"
        style={{ maxWidth: "none", position: null }}
      />
      {video?.video?.mp4Url && (
        <video
          loop
          autoPlay
          muted
          playsInline
          className="hidden md:block w-full h-full absolute top-0 left-0 z-20"
        >
          <source src={video?.video?.mp4Url} type="video/mp4" />
        </video>
      )}
      <div
        className={`py-32 md:py-64 xl:py-80 absolute z-30 top-0 left-0 w-full h-full flex items-center justify-center flex-col flex-nowrap`}
      >
        {/* <div className="w-full h-full absolute top-0 left-0 bg-black opacity-40 z-10"></div> */}
        <div className="z-30 container mx-auto px-10">
          <div className="grid xl:grid-cols-14">
            <div className="xl:col-span-12 xl:col-start-2">
              <h1 className="line-styled-heading max-w-2xl relative text-4xl md:text-5xl lg:text-6xl text-white font-extrabold z-20">
                <ReactMarkdown source={heading} />
              </h1>
              {logo && (
                <GatsbyImage
                  className="max-w-[300px] md:max-w-[450px] mx-auto block drop-shadow-xl"
                  image={logo}
                  objectFit="contain"
                  objectPosition="center"
                  alt={logo.alt ? logo.alt : "Nearlight Studio"}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <LinkWithIcon
        color="mi-light"
        clickHandler={(e) => handleDownClick(e)}
        icon="arrowDown"
        className={`hidden sm:block absolute bottom-12 left-1/2 transform -translate-x-1/2 z-30 transition-all duration-800 ${
          scrollTop > 0
            ? "translate-y-4 opacity-0 invisible pointer-events-none"
            : "opacity-100 visible"
        } select-none`}
      />
    </div>
  )
}

export default ImageHero

import LinkWithIcon from '@components/LinkWithIcon'
import { mapCmsColour } from '@config/helpers'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React, { useState } from 'react'
import Link from '@components/Link'

import './styles.scss'

const Projects = ({
    blade: {
        featuredGame,
        featuredLabelColour,
        featuredTextColour,
        games = [],
        id,
        linkText,
        verticalLabel,
    },
}) => {
    const [index, setIndex] = useState(0)

    const featuredGameImage = getImage(featuredGame.heroImage.gatsbyImageData)

    return (
        <div className="pt-10 pb-8 md:pb-32 lg:pb-48">
            <div className="container mx-auto px-10 z-20 relative">
                <div className="grid grid-cols-1 md:grid-cols-14">
                    <div className="hidden xl:block">
                        <div className="vert-label">{verticalLabel}</div>
                    </div>
                    <div className="col-span-full xl:col-span-12">
                        <h2
                            className={`featured-game__heading text-black color-${mapCmsColour(
                                featuredLabelColour
                            )} text-4xl sm:text-5xl md:text-6xl font-extrabold uppercase col-span-full`}
                        >
                            Projects
                        </h2>
                        {featuredGame && (
                            <div className="featured-game grid grid-cols-1 md:grid-cols-14 mt-8 md:h-[400px] lg:h-[500px]">
                                <div className="featured-game__image md:col-span-9 col-start-1 h-full">
                                    <a
                                        href={`https://ndreams.com/games/${featuredGame.slug}`}
                                        rel="noreferrer noopenner"
                                    >
                                        <GatsbyImage
                                            image={featuredGameImage}
                                            alt={
                                                featuredGame.heroImage.alt
                                                    ? featuredGame.heroImage.alt
                                                    : 'Featured Game Image'
                                            }
                                            style={{ position: null }}
                                            className="h-full w-full"
                                            objectFit="cover"
                                            objectPosition="center"
                                        />
                                    </a>
                                </div>
                                <div className="md:col-start-11 md:col-span-4 flex flex-col items-start justify-center mt-8 md:mt-0">
                                    <h3
                                        className={`featured-game__heading color-${mapCmsColour(
                                            featuredLabelColour
                                        )} text-4xl sm:text-5xl md:text-6xl font-extrabold uppercase border-b border-dashed border-aldo pb-10`}
                                    >
                                        {featuredGame.title}
                                    </h3>

                                    <div className="mt-10">
                                        <LinkWithIcon
                                            color={`mi-dark md-${mapCmsColour(
                                                featuredTextColour
                                            )}`}
                                            to={`https://ndreams.com/games/${featuredGame.slug}`}
                                            text="See more"
                                            icon="arrowRight"
                                            external={true}
                                            newTab={false}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                        {games && (
                            <div className="grid grid-cols-1 md:grid-cols-14 gap-6 gap-y-12 mt-8">
                                {games.map((game, index) => {
                                    const gameImage = getImage(
                                        game.heroImage.gatsbyImageData
                                    )
                                    const gameLogo = getImage(
                                        game.logo.gatsbyImageData
                                    )
                                    return (
                                        <div className="col-span-7" key={index}>
                                            <Link
                                                to={`https://ndreams.com/games/${game.slug}`}
                                                newTab={false}
                                                external={true}
                                                className="link-with-icon-hover flex flex-col group"
                                            >
                                                <div className="relative">
                                                    <GatsbyImage
                                                        image={gameImage}
                                                        className="h-full w-full"
                                                        alt={
                                                            game.heroImage.alt
                                                                ? game.herImage
                                                                      .alt
                                                                : 'Game Image'
                                                        }
                                                        style={{
                                                            position: null,
                                                        }}
                                                        objectFit="cover"
                                                        objectPosition="center"
                                                        className="h-[275px] lg:h-[350px]"
                                                    />
                                                    {game.secondaryColour
                                                        ?.hex && (
                                                        <span
                                                            className="block w-full h-full absolute top-0 left-0 opacity-0 invisible group-hover:visible group-hover:opacity-50 transition-all duration-200"
                                                            style={{
                                                                backgroundColor:
                                                                    game
                                                                        .secondaryColour
                                                                        .hex,
                                                            }}
                                                        />
                                                    )}

                                                    <GatsbyImage
                                                        image={gameLogo}
                                                        alt={
                                                            game.logo.alt
                                                                ? game.logo.alt
                                                                : `${game.title} Logo`
                                                        }
                                                        className="w-7/12 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                                                        style={{
                                                            position:
                                                                'absolute',
                                                        }}
                                                    />
                                                </div>
                                                <div className="flex flex-col sm:flex-row flex-1 sm:items-center sm:justify-between py-8 lg:pr-8 xl:pr-12">
                                                    <h3 className="line-styled-heading flex-0 md:max-w-xs sm:pr-8 md:pr-20 text-3xl md:text-4xl font-extrabold uppercase">
                                                        {game.title}
                                                    </h3>
                                                    <LinkWithIcon
                                                        color="mi-dark"
                                                        clickHandler={() =>
                                                            true
                                                        }
                                                        text="See more"
                                                        icon="arrowRight"
                                                        className="mt-4 sm:mt-0 sm:whitespace-nowrap"
                                                    />
                                                </div>
                                            </Link>
                                        </div>
                                    )
                                })}
                            </div>
                        )}
                    </div>
                </div>
                <div className="bg-aldo absolute -top-96 left-1/2 w-60 h-[650px] transform rotate-[40deg] translate-x-1/2 -z-10 xl:block hidden bg-opacity-20" />
            </div>
        </div>
    )
}

export default Projects

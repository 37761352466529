import React, { useState, useEffect } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import * as _ from "underscore"
import ReactMarkdown from "react-markdown"

import Icon from "@components/Icon"

const MeetTheTeam = ({ blade: { heading, teamGrid, showGap = false } }) => {
  const [navigation, setNavigation] = useState(true)
  const [animated, setAnimated] = useState(true)
  const [index, setIndex] = useState(0)
  const [itemWidth, setItemWidth] = useState(800)
  const [items] = useState([...teamGrid, ...teamGrid, ...teamGrid, ...teamGrid])

  const carouselWidth = items.length * itemWidth
  const [carouselOffset, setCarouselOffset] = useState(0)
  const offset = (index / items.length) * 100

  const handlePrev = () => {
    if (!navigation) return false

    const _resetPrev = index <= -teamGrid.length + 1

    setNavigation(false)
    setAnimated(true)
    setIndex(index - 1)

    setTimeout(() => {
      setNavigation(true)

      if (_resetPrev) {
        setAnimated(false)
        setIndex(0)
      }
    }, 300)
  }

  const handleNext = (auto = false) => {
    if (!navigation || (auto && window.innerWidth > 768)) return false

    const _resetNext = index >= teamGrid.length - 1

    setNavigation(false)
    setAnimated(true)
    setIndex(index + 1)

    setTimeout(() => {
      setNavigation(true)

      if (_resetNext) {
        setAnimated(false)
        setIndex(0)
      }
    }, 300)
  }

  const handleWindowResize = () => {
    if (window.innerWidth >= 1024) {
      setItemWidth(800)
    } else if (window.innerWidth >= 768) {
      setItemWidth(600)
    } else {
      setItemWidth(window.innerWidth - 60)
    }

    setCarouselOffset(window.innerWidth / 2 + itemWidth / 2)
  }

  useEffect(() => {
    const handleResize = _.throttle(() => handleWindowResize(), 1000)
    handleWindowResize()

    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [itemWidth, setItemWidth])

  useEffect(() => {
    let interval = null

    interval = setInterval(() => {
      handleNext(true)
    }, 3500)

    return () => clearInterval(interval)
  }, [])

  return (
    <div className={`${showGap ? "py-12 md:py-20" : "py-0"} overflow-hidden`}>
      <div className="container mx-auto px-10 relative text-center">
        <div className="grid grid-cols-full lg:grid-cols-14">
          <div className="col-span-12 col-start-2">
            {heading ? (
              <h2 className="max-w-2xl mx-auto mb-8 md:mb-12 relative text-3xl md:text-4xl lg:text-5xl font-semibold text-pink z-20">
                {heading}
              </h2>
            ) : null}
          </div>
        </div>
      </div>

      <div className="relative">
        <button
          onClick={() => handlePrev()}
          className="group hidden md:flex items-center justify-center w-16 h-16 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 border-2 border-pink text-center rounded-full z-10 hover:border-opacity-50 z-20"
          style={{
            marginTop: -itemWidth / 4 / 2,
            marginLeft: -itemWidth / 2,
          }}
        >
          <Icon
            name="arrowLeft"
            className="text-pink opacity-50 group-hover:opacity-100"
          />
        </button>
        <button
          onClick={() => handleNext()}
          className="group hidden md:flex items-center justify-center w-16 h-16 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 border-2 border-pink text-center rounded-full z-10 hover:border-opacity-50 z-20"
          style={{
            marginTop: -itemWidth / 4 / 2,
            marginLeft: itemWidth / 2,
          }}
        >
          <Icon
            name="arrowRight"
            className="text-pink opacity-50 group-hover:opacity-100"
          />
        </button>
        <div
          className={`flex flex-nowrap ${
            animated ? "transition-all" : "transition-none"
          } duration-300 mx-auto relative z-10`}
          style={{
            width: carouselWidth,
            transform: `translate3d(${carouselOffset}px, 0, 0) translateX(-${
              offset + ((teamGrid.length * 2 + 1) / items.length) * 100
            }%)`,
          }}
        >
          {items?.map(({ image, heading, body }, i) => {
            const img = getImage(image.gatsbyImageData)

            return (
              <div
                key={i}
                className="md:min-w-[600px] lg:min-w-[800px] md:w-[600px] lg:w-[800px] text-center"
              >
                <div className="p-3 md:p-12">
                  <GatsbyImage
                    image={img}
                    alt={image.alt ? image.alt : "Nearlight Studio"}
                    className="w-full max-w-6xl mb-4 md:mb-8 relative rounded-2xl"
                    style={{ position: null }}
                    objectFit="cover"
                  />
                  <div
                    className={`${
                      index === i - teamGrid.length * 2
                        ? "opacity-100 pointer-events-auto"
                        : "opacity-0 pointer-events-none"
                    }`}
                  >
                    <h3 className="mb-4 font-semibold text-xl text-pink">
                      {heading}
                    </h3>
                    <span className="text-sm">
                      <ReactMarkdown>{body}</ReactMarkdown>
                    </span>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default MeetTheTeam

import arrowUp from "./arrow-up.svg"
import arrowRight from "./arrow-right.svg"
import arrowDown from "./arrow-down.svg"
import arrowLeft from "./arrow-left.svg"
import angleUp from "./angle-up.svg"
import angleDown from "./angle-down.svg"
import play from "./play.svg"
import check from "./check.svg"
import search from "./search.svg"
import plus from "./plus.svg"
import minus from "./minus.svg"
import cross from "./cross.svg"

import twitter from "./twitter.svg"
import youtube from "./youtube.svg"
import facebook from "./facebook.svg"
import discord from "./discord.svg"
import instagram from "./instagram.svg"

import art from "./art.svg"
import audio from "./audio.svg"
import code from "./code.svg"
import design from "./design.svg"
import operations from "./operations.svg"
import publisher from "./publisher.svg"
import production from "./production.svg"
import qa from "./qa.svg"

export default {
  arrowUp,
  arrowRight,
  arrowDown,
  arrowLeft,
  angleUp,
  angleDown,
  play,
  check,
  search,
  plus,
  minus,
  cross,

  twitter,
  youtube,
  facebook,
  discord,
  instagram,

  art,
  audio,
  code,
  design,
  operations,
  publisher,
  production,
  qa,
}

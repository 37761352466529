import React, { useState, useEffect } from "react"

import { validateEmail } from "@config/helpers"

import Select from "@components/Inputs/Select"
import Text from "@components/Inputs/Text"
import Textarea from "@components/Inputs/Textarea"
import Button from "@components/Button"

const subjects = [
  { value: "general", label: "General" },
  { value: "recruitment", label: "Recruitment" },
  { value: "3rd-party-publishing", label: "3rd party publishing" },
  { value: "business-development", label: "Business development" },
  { value: "game-support", label: "Game support" },
  { value: "press-and-creators", label: "Press & creators" },
]

const ContactForm = ({}) => {
  const [progress, setProgress] = useState("none")
  const [feedback, setFeedback] = useState("")

  const [subject, setSubject] = useState(subjects[0])

  const [message, setMessage] = useState("")
  const [validMessage, setValidMessage] = useState(false)

  const [email, setEmail] = useState("")
  const [validEmail, setValidEmail] = useState(false)

  const [fileName, setFileName] = useState(false)

  useEffect(() => {
    setValidEmail(progress === "none" || validateEmail(email))
    setValidMessage(
      progress === "none" || message.replaceAll(" ", "").length > 0
    )
  }, [email, message, progress])

  const handleSubmit = (e) => {
    e.preventDefault()

    setFeedback("")
    setProgress("pending")

    if (validEmail && message) {
      const form = new FormData(e.target)

      const options = {
        method: "POST",
        headers: {},
        body: form,
      }
      delete options.headers["Content-Type"]

      fetch("/", options)
        .then(() => {
          setProgress("success")
          setFeedback(
            "Thank you for your enquiry, we will be in touch as soon as possible."
          )
        })
        .catch(() => {
          setProgress("pending")
          setFeedback(
            "Something went wrong, please contact us directly or try again."
          )
        })
    }
  }

  return (
    <>
      <form
        name={subject.value}
        method="post"
        data-netlify="true"
        netlify-honeypot="bot-field"
        action=""
        onSubmit={handleSubmit}
      >
        {progress !== "success" && (
          <>
            <label className="hidden">
              Don’t fill this out if you're human: <input name="bot-field" />
            </label>
            <input type="hidden" name="form-name" value={subject.value} />
            <input type="hidden" name="subject" value={subject.label} />
            <div className="flex items-start flex-col md:flex-row">
              <Select
                {...{
                  name: "subject",
                  color: "dark",
                  placeholder: "Choose subject",
                  options: subjects,
                  value: subject.value,
                  required: true,
                  className:
                    "w-full py-2 mb-4 md:mb-0 text-base text-white placeholder-white",
                  setValue: (e) =>
                    setSubject(subjects.find(({ value }) => value === e)),
                }}
              />
              <div className="w-full md:w1/2 mb-4 md:mb-0 md:ml-8">
                <Text
                  {...{
                    name: "email",
                    placeholder: "Email",
                    value: email,
                    className:
                      "w-full py-2 text-base text-white placeholder-white",
                    required: true,
                    changeHandler: ({ currentTarget: { value } }) =>
                      setEmail(value),
                  }}
                />
                {!validEmail && (
                  <p className="mt-4 text-red font-normal">
                    *Enter valid email
                  </p>
                )}
              </div>
            </div>
            <Textarea
              {...{
                name: "message",
                placeholder: "Message",
                value: message,
                required: true,
                className:
                  "w-full py-2 mt-4 text-base text-white placeholder-white",
                changeHandler: ({ currentTarget: { value } }) =>
                  setMessage(value),
              }}
            />
            {!validMessage && (
              <p className="mt-4 text-red font-normal">*Enter your message</p>
            )}
            <div className="flex flex-col md:flex-row-reverse items-start md:items-center justify-end mt-4 md:mt-8">
              <input
                id="cv"
                name="cv"
                type="file"
                className="hidden"
                data-filename={fileName || "Attach File"}
                onChange={(e) => setFileName(e.target?.files?.[0]?.name)}
              />
              <label
                htmlFor="cv"
                className="inline-flex items-center py-2 px-4 md:px-6 md:ml-4 font-bold uppercase cursor-pointer border-2 border-solid outline-none transition duration-200 text-lg bg-black hover:bg-white border-white hover:text-black text-white"
              >
                {fileName || "Attach File"}
              </label>
              <Button
                clickHandler={() => true}
                type="submit"
                className="mt-4 md:mt-0 px-12 hover:bg-white hover:border-white hover:text-pink"
              >
                Submit
              </Button>
            </div>
          </>
        )}
        {feedback && <p className="mt-4 text-white font-normal">{feedback}</p>}
      </form>
    </>
  )
}

export default ContactForm
